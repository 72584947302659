<template>
  <b-container fluid class="animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{$t(errorText)}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
      {{$t('La acción fue completada con éxito')}}
    </div>

    <b-row>
      <b-col>
        <button class="btn btn-primary float-right" @click="goCreateUser()">{{$t('Crear usuario')}}</button>
        <button class="btn btn-primary float-right mr-2" v-b-toggle.collapse2 @click="showFilters = !showFilters">{{$t('Filtros')}}</button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse id="collapse2">
          <b-card class="filters mt-3 p-3">
            <b-form-group horizontal
              :label-cols="2"
              breakpoint="md"
              :label="$t('Buscar')"
              label-for="nameLabelFormater">
              <b-form-input
                type="text"
                id="nameLabelFormater"
                @keypress.native="validateNotNumbers"
                v-model="filterRules.user.text">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col>
                <button class="btn btn-primary float-right mr-2" :disabled='!enableFilter || loading' @click="$refs.mainTable.refresh()">{{$t('Filtrar')}}</button>
                <button class="btn btn-primary float-right mr-2" :disabled='loading' @click="resetFilters">{{$t('Mostrar Todos')}}</button>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="filterRules.user.size" v-model="filterRules.user.from" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto per-page">
        <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
          <b-form-select :options="pageOptions" v-model="filterRules.user.size" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="mt-1" align-self="start">
        <b>{{totalRows}}</b> {{$t('usuarios encontrados')}}
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de Usuarios')">
          <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
          <b-table v-show="!loading" id="mainTable"
            ref="mainTable"
            responsive=true
            stacked="xl"
            :empty-text="$t('No hay usuarios para mostrar')"
            :empty-filtered-text="$t('No hay usuarios para mostrar')"
            show-empty
            striped
            hover
            foot-clone
            :sort-by.sync="filterRules.user.sortBy"
            :sort-desc.sync="filterRules.user.sortOrder"
            :per-page="filterRules.user.size"
            :current-page="filterRules.user.from"
            :items="itemProvider"
            :fields="fields"
            :filter="filter"
            @row-clicked="goEditUser">

            <template slot="userType" slot-scope="row">
              <b-badge class="mr-1" pill>{{row.item.userType}} </b-badge>
            </template>
            <template slot="seller" slot-scope="row">
              <div v-if="row.item.userType==='ADMIN'">
                <b-badge class="mr-1" pill variant="">Todos</b-badge>
              </div>
              <div v-if="row.item.userType==='SELLER'">
                <b-badge class="mr-1" pill v-for="seller in row.item.organizations" variant="primary" :key="seller.id">{{seller.name}}</b-badge>
              </div>
            </template>
            <template slot="roles" slot-scope="row">
              <b-badge class="mr-1" pill v-for="role in row.item.roles" :key="role.roleName">{{role.roleName}} </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      header-bg-variant="primary"
      ref="userInfo"
      centered
      :title="selectedUser.id ? $t('Guardar usuario') : $t('Crear usuario')"
      size="lg">
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <user-form
              ref="userForm"
              :user="selectedUser"
              :roleOptions="roleOptions"
              :allOrganizations="allOrganizations"
              :action="action"
              />
          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-row>
          <b-col class="px-4">
            <button class="btn btn-primary mb-2 mx-1" v-if="selectedUser.id" @click="handleResetPassword()">{{$t('Reiniciar clave')}}</button>
            <button class="btn btn-primary mb-2 mx-1 float-right" @click="cancelModal">{{$t('Cancelar')}}</button>
            <button class="btn btn-primary mb-2 mx-1 float-right"
              :disabled="this.$refs.userForm ? this.$refs.userForm.disableSave : true"
              @click="handleSave">{{selectedUser.id ? $t('Guardar') : $t('Crear')}}</button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
  import {AdminServiceMixin, RoleService, OrganizationService} from '@/mixims/AdminServiceMixin'
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
  import UserForm from './UserForm'
  import OrganizationsCheckbox from './OrganizationsCheckbox'
  import RolesCheckbox from './RolesCheckbox'
  import { ValidationsMixim } from '@/mixims/ValidationsMixim'

  export default {
    name: 'users-list',
    components: {MoonLoader, UserForm},
    mixins: [AdminServiceMixin, RoleService, OrganizationService, ValidationsMixim],
    data: function () {
      return {
        action: 'create',
        success: null,
        errorText: '',
        loading: true,
        fields: [
          { key: 'userType', label: this.$t('Tipo'), 'class': 'text-left', sortable: true },
          { key: 'firstName', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
          { key: 'lastName', label: this.$t('Apellido'), 'class': 'text-left', sortable: true },
          { key: 'email', label: this.$t('Correo'), 'class': 'text-left', sortable: true },
          { key: 'seller', label: this.$t('Seller'), 'class': 'text-left', sortable: false },
          { key: 'roles', label: this.$t('Roles'), sortable: false }
        ],
        selectedUser: {
          firstName: null,
          lastName: null,
          email: null,
          id: null,
          roles: [],
          organizations: this.$store.getters.isAdmin ? [] : this.$store.state.user_data.organizations
        },
        roleOptions: [],
        allOrganizations: [],
        showFilters: false,
        filterRules: {
          user: {
            text: null,
            firstName: null,
            lastName: null,
            email: null,
            roles: [],
            accountNames: [],
            sortBy: 'firstName',
            sortOrder: true,
            size: 10,
            from: 1
          }
        },
        totalRows: 0,
        pageOptions: [ 10, 15, 25, 50, 100 ],
        timeout: null
      }
    },
    mounted () {
      this.getRoles().then(roles => {
        this.roleOptions = roles.body
      })
      this.getAllOrganizations().then(orgs => {
        this.allOrganizations = orgs
      })
    },
    methods: {
      resetFilters () {
        this.filterRules = {
          user: {
            text: null,
            firstName: null,
            lastName: null,
            email: null,
            roles: [],
            accountNames: [],
            sortBy: 'firstName',
            sortOrder: true,
            size: 10,
            from: 1
          }
        }
        this.$refs.mainTable.refresh()
      },
      cancelModal () {
        this.$refs.userInfo.hide()
        this.resetModal()
      },
      filter () {
        return this.filterRules
      },
      addIfNotPresent (list, item) {
        if (!list.includes(item)) list.push(item)
      },
      itemSort (sortBy) {
        return (a, b) => {
          return a[sortBy] > b[sortBy] ? 1 : -1
        }
      },
      handleResetPassword () {
        this.resetPassword(this.selectedUser.id).then(() => {
          this.success = true
          this.$refs.userInfo.hide()
        }).catch(er => {
          this.errorText = er.body.message || 'Ocurrió un error al reestablecer una contraseña'
        })
      },
      handleSave () {
        this.loading = true
        this.$refs.userInfo.hide()
        this.$refs.userForm.save(user => {
          let save = user.id ? this.updateUser : this.createUser
          save(user).then(() => {
            this.loading = false
            this.success = true
            this.$refs.mainTable.refresh()
          }).catch(er => {
            this.loading = false
            this.errorText = er.body.message || 'Ocurrió un error al crear el usuario'
            if (this.errorText.length > 64) this.errorText = 'Ocurrió un error al crear el usuario'
          })
        })
      },
      goEditUser (user) {
        this.$router.push({ path: `user/${user.id}` })
      },
      goCreateUser () {
      this.$router.push({ path: 'user' })
      },
      openUserModal (user, action) {
        this.action = action === 'create' ? 'create' : 'update'
        this.resetModal()
        this.selectedUser = {...user}
        this.$refs.userInfo.show()
      },
      resetModal () {
        this.selectedUser.firstName = null
        this.selectedUser.lastName = null
        this.selectedUser.email = null
        this.selectedUser.id = null
        this.selectedUser.roles = []
        this.selectedUser.organizations = this.$store.getters.isAdmin ? [] : this.$store.state.user_data.organizations
      },
      itemProvider (ctx) {
        let filter = ctx.filter().user
        filter.sortBy = 'firstName.keyword'
        this.loading = true
        return this.getUsers(filter).then(
          response => {
            this.totalRows = parseInt(response.headers.get('X-Total-Count'))
            this.loading = false
            return response.body
          },
          error => {
            this.errorText = error.body.message || 'Error obteniendo el listado de usuarios'
            this.loading = false
            return []
          }
        )
      },
      validateNotNumbers (e) {
        if ((e.which <= 57 && e.which >= 48)) {
          e.preventDefault()
          return false
        }
      }
    },
    computed: {
      enableFilter () {
        return Boolean(this.filterRules.user.text)
      }
    }
  }
</script>
