<template>
  <div>
    <h5 class="my-3">Tipo de usuario y Rol</h5>
    <b-row>
      <b-col cols="12">
        <b-form-group
        horizontal
        :label-cols="2"
        label="Tipo de usuario"
        :invalid-feedback="!state.userType ? 'Debe seleccionar un tipo de usuario' : ''"
        :state="state.userType">
          <b-form-select :options="userTypeOptions" v-model="user.userType" :disabled="!adminWrite || $store.getters.userData.userType === 'SELLER'"
            @change="onUserTypeChange">
            <option slot="first" :value="null" :disabled="true">Seleccione un tipo de usuario</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-form-group
        horizontal
        :label-cols="2"
        label="Rol"
        :invalid-feedback="!state.roles ? 'Debe seleccionar un rol' : ''"
        :state="state.roles">
          <b-form-select :options="roleOptions" v-model="roleSelected" :disabled="!adminWrite || $store.getters.userData.userType === 'SELLER'">
            <option slot="first" :value="null" :disabled="true">Seleccione un rol</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['user', 'isAvailable', 'state', 'roles', 'adminWrite'],
  methods: {
    onUserTypeChange (value) {
      this.roleSelected = null
      this.user.organizations = value === 'ADMIN' ? this.$store.state.user_data.organizations : []
    }
  },
  computed: {
    userTypeOptions () {
      let userTypes = [
        { value: 'SELLER', text: 'Seller' }
      ]
      if (this.$store.getters.userData.userType === 'ADMIN') {
        userTypes.unshift({ value: 'ADMIN', text: 'Administrador' })
      }
      return userTypes
    },
    roleOptions () {
      return this.roles.filter(r => r.userType === this.user.userType).map(r => {
        return {value: r.id, text: r.roleName}
      })
    },
    roleSelected: {
      get () {
        if (this.user.roles.length > 0) return this.user.roles[0].id ? this.user.roles[0].id : this.user.roles[0]
        return null
      },
      set (val) {
        this.user.roles = []
        if(val !== null){
          this.user.roles.push(val)
        }
      }
    }
  },
}
</script>